<template>
  <div id="notfound">
    <div class="notfound">
      <h2>The site is not currently available in your country.</h2>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("country", ["ipInfo"]),
  },

  watch: {
    ipInfo: {
      handler() {
        if (!(this.ipInfo && !this.ipInfo.visible_status)) {
          this.$router.push({ name: "home" });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound h2 {
  font-family: "Cabin", sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  margin-top: 0px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 480px) {
  .notfound h2 {
    font-size: 16px;
  }
}
</style>
